module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"header-link-icon","offsetY":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Riverscapes Gatsby Template Site","short_name":"RiverscapesTemplate","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"./static/viewer_favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"25cd018b04b6753d3acebed3ceaaa70c"},
    },{
      plugin: require('../node_modules/@riverscapes/gatsby-theme/gatsby-browser.js'),
      options: {"plugins":[],"contentPath":"/home/runner/work/RaveAddIn/RaveAddIn/docs/content/page","manifest":{"name":"Riverscapes Gatsby Template Site","short_name":"RiverscapesTemplate","start_url":"/","iconUrl":"./static/viewer_favicon.png"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
